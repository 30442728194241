import '@rainbow-me/rainbowkit/styles.css';
import '@/styles/global.css'

import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { Toaster } from 'react-hot-toast';
import { FLAMEYS_CHAIN_ID } from '@/lib/constants';
import Head from 'next/head';

const flameyChains = Object.values(chain).filter(c => c.id === FLAMEYS_CHAIN_ID)

const { chains, provider } = configureChains(
  flameyChains,
  [
    alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY, priority: 0 }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Tribo Flameys',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Flamey NFTs</title>
      </Head>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <Component {...pageProps} />
          <Toaster />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  )
}

export default MyApp
